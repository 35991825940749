import * as React from "react"
import { 
  GatsbyImage,
  getImage,
} from "gatsby-plugin-image"

export default function ContentBlocks({ blocks }) {
	return (
		<div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
      {blocks && blocks.map((block, index) => {
        const caption = block.caption
      	if (block.type === `news_text`) {
      		return (
      			<div key={`content-block-${index}`} className="textarea"
      				   dangerouslySetInnerHTML={{ __html: block.text }} />
      		)
      	} else if (block.type === `news_image`) {
      		return (
      			<figure key={`content-block-${index}`}>
      				<GatsbyImage className="w-full rounded-lg"
      				             image={getImage(block.image)}
      				             alt={block.alt} />
              {caption && <figcaption>{caption}</figcaption>}
          	</figure>
      		)
      	} else if (block.type === `left_aligned_image`) {
          return (
            <figure key={`content-block-${index}`}>
              <GatsbyImage className="w-full sm:w-1/2 rounded-lg sm:float-left sm:mr-8"
                           image={getImage(block.image)}
                           alt={block.alt} />
              {caption && <figcaption>{caption}</figcaption>}
            </figure>
          )
        }
      	return null
      })}
    </div>
	)
}