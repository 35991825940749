import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import ContentBlocks from "../components/paragraphs/content-blocks"
import * as striptags from "striptags"

const NewsItem = ({ data }) => {
  const news = data.news
  const { title, relationships, created } = news
  const category = relationships.field_news_category.name
  let openingParagraph = news?.field_news_opening?.processed ?? null
  const contentBlocks = relationships.field_content_blocks && (relationships.field_content_blocks.map(block => {
    let type = block.__typename.substring(11),
        text = null,
        image = null,
        alt = ``,
        caption = ``
    if (type === `news_image`) {
        if (block.relationships.field_news_media) {
          image = block.relationships.field_news_media.relationships.field_media_image.localFile
          alt = block.relationships.field_news_media.field_media_image.alt
          caption = block.relationships.field_news_media.field_image_caption ?? ``
        }
    } else if (type === `left_aligned_image`) {
      if (block.relationships.field_news_media) {
        image = block.relationships.field_news_media.relationships.field_media_image.localFile
        alt = block.relationships.field_news_media.field_media_image.alt
        caption = block.relationships.field_news_media.field_image_caption ?? ``
      }
    } else if (type === `news_text`) {
      text = block.field_news_body.processed
    }
  	return {
  		type: type,
  		text: text,
  		image: image,
  		alt: alt,
      caption: caption,
  	}
  }) || null)
  return (
    <Layout showSubnav="true">
      <div className="relative overflow-hidden bg-white pb-16 pt-32 lg:pt-64">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
          <svg className="opacity-75 fill-gray-100 absolute -bottom-[35rem] left-full hidden lg:block w-11/12 transform -translate-x-2/4" viewBox="0 0 312 239">
            <path d="M162.713 222.751L216.32 58.3815C257.999 40.4249 303.174 20.9303 311.085 17.5158L311.999 16.2511L312 16.2506L162.713 222.751ZM107.172 226.654C107.172 226.654 77.059 143.154 76.7899 142.41C76.5879 141.87 72.0789 129.242 85.234 118.205C85.32 118.135 94.721 110.524 104.296 102.928L129.24 157.51C129.246 157.549 129.253 157.587 129.26 157.626L129.289 157.621L160.206 225.275L160.449 225.867C155.292 232.83 148.725 239 133.792 239C120.304 239 112.555 231.96 107.172 226.654ZM105.253 101.147C144.235 70.2672 223.162 8.4806 233.566 0.338028L215.021 57.1994C178.915 72.754 145.628 87.07 141.782 88.6378C131.301 92.9098 119.499 102.054 122.784 121.351C123.853 127.632 125.819 138.678 128.074 151.11L105.326 101.334L105.253 101.147ZM104.102 101.04C94.252 108.845 84.315 116.892 84.216 116.971C70.158 128.768 75.236 142.822 75.289 142.963L104.581 224.19L0.463922 126.318L29.5199 84.4332C38.6429 74.4596 49.932 70.0202 61.059 70.0202C78.203 70.0216 94.959 80.5685 102.78 97.6749L104.24 100.879L104.102 101.04ZM130.778 157.027L160.939 223.027L214.361 59.2252C178.747 74.567 146.192 88.5665 142.385 90.1183C132.449 94.1706 121.255 102.83 124.362 121.082C125.604 128.387 128.059 142.139 130.778 157.027Z" />
          </svg>
        </div>
        <div className="relative mx-auto max-w-xl lg:max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h1>
              <span className="block text-lg font-semibold text-sky-700">{category}</span>
              <span className="mt-2 block text-3xl font-bold leading-8 tracking-tight text-sky-900 sm:text-4xl">
                {title}
              </span>
            </h1>
            <div className="mt-4 text-gray-300 text-sm">{created}</div>
            {openingParagraph && <div className="mt-8 text-xl leading-8 text-gray-500"
            		 dangerouslySetInnerHTML={{ __html: openingParagraph }} />}
          </div>
          {contentBlocks && <ContentBlocks blocks={contentBlocks} />}
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const news = data.news
  const { title, field_metatags, field_teaser_text, path } = news
  const metaDescription = field_metatags?.description ?? field_teaser_text
  const siteUrl = process.env.GATSBY_SITE_URL.replace(/\/+$/, '')
  const canonicalUrl = `${siteUrl}${path.alias}/`
  return (
    <>
      <meta name="description" content={striptags(metaDescription)} />
      <title>{title} | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export default NewsItem

export const query = graphql`
  query NewsItemQuery($id: String!) {
    news: nodeNews(id: {eq: $id}) {
    	created(formatString: "MMM DD, YYYY")
      title
      field_metatags {
        description
      }
      field_news_opening {
        processed
      }
      field_teaser_text
      path {
        alias
      }
      relationships {
        field_news_category {
          name
        }
        field_content_blocks {
          ... on Node {
            ...LeftAlignedImageFields
            ...NewsImageFields
            ...NewsTextFields
          }
        }
      }
    }
  }
`